import { provideAuth, connectAuthEmulator, getAuth } from '@angular/fire/auth'

import { AUTH } from './emulator-ports'

// import 'firebase/auth'

export function getAuthModule(useEmulators = false) {
  return provideAuth(() => {
    const auth = getAuth()
    if (useEmulators) {
      connectAuthEmulator(auth, `http://localhost:${AUTH}`, {
        disableWarnings: true,
      })
    }
    return auth
  })
}
