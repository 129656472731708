import { Provider } from '@angular/core'
import { provideLottieOptions, provideCacheableAnimationLoader } from 'ngx-lottie'
export async function lottiePlayerFactory() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const player = (await import(/* webpackChunkName: 'lottie-web' */ 'lottie-web')) as any
  return Promise.resolve({
    ...player,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    useWebWorker: () => {},
  })
}

export function getLottieProviders(useWebWorker = false): Provider[] {
  return [
    provideLottieOptions({
      // ngx-lottie内部でuseWebWorkerの関数がないというエラーがビルドファイルのみで発生する為、useWebWorkerをラップさせる
      // player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web'),
      player: () => lottiePlayerFactory(),
      useWebWorker,
    }),
    provideCacheableAnimationLoader(),
  ]
}
