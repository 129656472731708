import { getApp } from '@angular/fire/app'
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions'
export { FunctionsModule } from '@angular/fire/functions'

import { FUNCTIONS } from './emulator-ports'

export function getFunctionsModule(useEmulators = false) {
  return provideFunctions(() => {
    const functions = getFunctions(getApp(), 'asia-northeast1')
    if (useEmulators) {
      connectFunctionsEmulator(functions, 'localhost', FUNCTIONS)
    }
    return functions
  })
}
