import { getApp } from '@angular/fire/app'
import {
  connectFirestoreEmulator,
  provideFirestore,
  persistentLocalCache,
  initializeFirestore,
  persistentMultipleTabManager,
} from '@angular/fire/firestore'

import { FIRESTORE } from './emulator-ports'

// let resolvePersistenceEnabled: (enabled: boolean) => void
//
// export const persistenceEnabled = new Promise<boolean>(resolve => {
//   resolvePersistenceEnabled = resolve
// })

export function getFirestoreModule(useEmulators = false) {
  return provideFirestore(() => {
    // const firestore = getFirestore()
    const settings = useEmulators
      ? {}
      : {
          localCache: persistentLocalCache({
            tabManager: persistentMultipleTabManager(),
          }),
        }
    const firestore = initializeFirestore(getApp(), settings)
    if (useEmulators) {
      connectFirestoreEmulator(firestore, 'localhost', FIRESTORE)
    }
    // if (useEmulators) {
    //   enableMultiTabIndexedDbPersistence(firestore).then(
    //     () => resolvePersistenceEnabled(true),
    //     () => resolvePersistenceEnabled(false)
    //   )
    //   // FirestoreSettings
    // }
    return firestore
  })
}
