import { getApp } from '@angular/fire/app'
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage'

import { STORAGE } from './emulator-ports'

export function getStorageModule(useEmulators = false, bucket?: string) {
  return provideStorage(() => {
    const storage = getStorage(getApp(), bucket)
    if (useEmulators) {
      connectStorageEmulator(storage, 'localhost', STORAGE)
    }
    return storage
  })
}
