<div class="p-errors-404">
  <!--
  <div class="l-header" style="background-image: url(assets/images/wave.svg)">
    <h1><img src="assets/images/not_found_img@3x.png" class="404 Page Not Found" /></h1>
  </div>
-->
  <div class="l-header">
    <h1><img src="assets/images/logo_livmap.png" class="logo" alt="LivMap" /></h1>
  </div>
  <div class="l-message">
    <h2>ページが見つかりませんでした</h2>
    <p class="description">ページが削除・変更されたか<br />現在利用できない可能性があります</p>
  </div>
  <div class="l-button">
    <div class="button-top">
      <a mat-flat-button [href]="appUrl" color="primary"> livmap トップへ </a>
    </div>
    <div class="button-line">
      <a mat-flat-button href="https://lin.ee/BWKhSqM" color="primary">
        <img src="assets/images/icon-line.svg" />
        LINEで問い合わせ
      </a>
    </div>
  </div>
  <div class="l-footer">© Hamposaki inc.</div>
</div>
