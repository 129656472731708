import { Route } from '@angular/router'

import { PageNotFoundComponent } from './pages/errors/page-not-found/page-not-found.component'
import { guestGuard, adminGuard } from './pages/pages.guard'

export const routes: Route[] = [
  {
    path: 'errors/404',
    pathMatch: 'full',
    component: PageNotFoundComponent,
  },
  {
    path: 'auth',
    canMatch: [guestGuard],
    loadChildren: () => import('./pages/guest/guest.routes').then(r => r.routes),
  },
  {
    path: '',
    canMatch: [adminGuard],
    loadChildren: () => import('./pages/admin/admin.routes').then(r => r.routes),
  },
  {
    path: '**',
    redirectTo: '/errors/404',
  },
]
