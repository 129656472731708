import { inject } from '@angular/core'
import { CanMatchFn, Router } from '@angular/router'
import { PARSE_URLS } from '@libs/configs/navigates'
import { of, throwError } from 'rxjs'
import { mergeMap, first, catchError, map } from 'rxjs/operators'

import { AuthService } from '~services/firebase/auth.service'

const REDIRECT = 'redirectUrl'

const ERROR_CODES = {
  auth_is_not_admin: 'auth/admin/deny',
  auth_unauthorized: 'auth/unauthorized',
  auth_authorized: 'auth/authorized',
  client_deny: 'auth/client/deny',
  no_clients: 'client/empty',
}
type ErrorCode = (typeof ERROR_CODES)[keyof typeof ERROR_CODES]

interface ErrorIf {
  code: ErrorCode
}
export const guestGuard: CanMatchFn = (_route, _segments) => {
  const authSv = inject(AuthService)
  const router = inject(Router)
  return authSv.isGuest().pipe(
    first(),
    mergeMap(isGuest => {
      if (!isGuest) {
        return throwError(() => ({ code: ERROR_CODES.auth_authorized }))
      }
      return of(true)
    }),
    catchError((e: ErrorIf) => {
      switch (e.code) {
        default:
        case ERROR_CODES.auth_authorized:
          return authSv.isAdmin().pipe(
            mergeMap(isAdmin => {
              if (isAdmin) {
                return of(router.parseUrl(PARSE_URLS.ADMIN))
              }
              return authSv.signOut().pipe(map(() => true))
            })
          )
      }
    })
  )
}

export const adminGuard: CanMatchFn = (_route, _segments) => {
  const authSv = inject(AuthService)
  const router = inject(Router)
  return authSv.isAdmin().pipe(
    first(),
    mergeMap(isAdmin => {
      if (!isAdmin) {
        return throwError(() => ({ code: ERROR_CODES.auth_is_not_admin }))
      }
      return of(true)
    }),
    catchError((e: ErrorIf) => {
      switch (e.code) {
        case ERROR_CODES.auth_is_not_admin:
          return authSv.isSignedIn().pipe(
            mergeMap(isSignedIn => {
              if (isSignedIn) {
                return authSv.signOut().pipe(map(() => router.parseUrl(PARSE_URLS.SIGN_IN)))
              }
              return of(router.parseUrl(PARSE_URLS.SIGN_IN))
            })
          )
        default:
          return of(router.parseUrl(PARSE_URLS.SIGN_IN))
      }
    })
  )
}
