import { bootstrapApplication } from '@angular/platform-browser'
import { init, browserTracingIntegration } from '@sentry/angular'

import { AppComponent } from './app/app.component'
import { appConfig } from './app/app.config'

import { environment } from '~env'

init({
  dsn: environment.sentryDsn,
  environment: environment.name,
  tracesSampleRate: 1.0,
  integrations: [
    browserTracingIntegration({
      // tracingOrigins: ['localhost', 'livmap-stg.web.app', 'livmap.web.app'],
      shouldCreateSpanForRequest: url => {
        return url.startsWith(environment.url)
      },
    }),
  ],
})

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err))
